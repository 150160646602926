// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'ar',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '1ccd686ab8451055cb672948026ea233a540ecb9',
    releaseTagRef: '7b34252',
    releaseTimestamp: '1743599066',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    urlScheme: 'burgerking',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
    },
    apiKeys: {
      amplitude: '5eb59fa70ca2d6a974a40297f5792d59',
      branch: '',
      braze: 'ae6e8af2-b352-4887-a632-97877e654397',
      brazeCluster: 'sdk.iad-06.braze.com',
      bringg: '',
      googleMaps: 'AIzaSyA7aLaI1U1fctVpj3y8aKDMZbFjJc9cYvU',
      tomtom: '',
      launchDarkly: '65ce455d0ef5bb0fe58359da',
      launchDarklyMobileKey: 'mob-925f1689-e0a3-4f55-a608-07f23fba0568',
      mParticle: 'us1-70841588f9e9ac4b845c060f77557f58',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: 'merchant.com.prod.native.bk.prod',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://use2-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://use2-prod-bk-gateway.rbictg.com/graphql',
      region: 'us-east-2',
      userPoolClientId: '5gc2jf0dcrpghu6ki5tm1j05tp',
      userPoolId: 'us-east-2_92M1jvbIZ',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: 'GTM-W7XSS52',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-05.braze.com/api/v3',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: 'C0003',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: '',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '0194b388-dcc5-7b42-ac87-0e5023143807',
        },
      },
    }
  };